import { useState } from 'react'
import styles from './index.module.scss'
import { referencesCompanyArr } from '/models/references-company'

const ReferencesCompany = () => {
  const [logos] = useState(referencesCompanyArr)

  /*const fade = async (index, fadeStatus) => {
    await new Promise((resolve) => setTimeout(resolve, 100))
    setLogos((prevLogos) =>
      prevLogos.map((logo, i) =>
        i === index ? { ...logo, fade: fadeStatus } : logo
      )
    )
    await new Promise((resolve) => setTimeout(resolve, 500))
  }

   const selectAndSwapRandomLogos = async () => {
    if (logos.length < 2) return

    const swapFirst = Math.floor(Math.random() * logos.length)
    let swapSecond
    do {
      swapSecond = Math.floor(Math.random() * logos.length)
    } while (swapSecond === swapFirst)

    await fade(swapFirst, true)
    await fade(swapSecond, true)

    setLogos((prevLogos) => {
      const newLogos = [...prevLogos]
      const temp = newLogos[swapFirst]
      newLogos[swapFirst] = newLogos[swapSecond]
      newLogos[swapSecond] = temp
      return newLogos
    })

    await fade(swapFirst, false)
    await fade(swapSecond, false)
  }

 useEffect(() => {
    const interval = setInterval(() => {
      selectAndSwapRandomLogos()
    }, 2500)

    return () => clearInterval(interval)
  }, [])*/

  return (
    <section className={styles.root}>
      <div className={`${styles.wrapper} container`}>
        <h2 className={styles.title}>İşin Olsun&apos;u Tercih Eden Firmalar</h2>
        <div className={styles.companies}>
          {logos?.map(({ id, imgUrl, alt, fade }) => (
            <img
              key={id}
              className={`${styles.image} ${
                fade ? styles.fadeOut : styles.fadeIn
              }`}
              src={imgUrl}
              alt={alt}
              loading="lazy"
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default ReferencesCompany
