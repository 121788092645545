import services from 'services'
import { formatNumberType1 } from 'utils/stringOperation'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { useGlobalContext } from 'contexts'
import PageHead from 'components/ui/pagehead'
import HomeHeroBanner from '@components/ui/home-hero-banner'
import HomeHeader2 from '@components/ui/homeheader2'
// import Features from '@components/ui/features2'
import EmployerInfo from '@components/ui/employerinfo'
import ReferencesCompany from '@components/ui/references-company'
import BlogContent from '@components/ui/blogcontent'
import SeoLinks from '@components/ui/seolinks2'
import HomeJobs from '@components/ui/homejobs'
import Footer2 from '@components/ui/footer-2'
import WikiContent from 'components/ui/wikiboxcontent2'
import AppRedirect from '@components/ui/appredirect'
import styles from './index.module.scss'
import {
  addSearchId,
  clearSearchIdUrl,
  deleteOldSearchId,
  generateSearchId,
  getSearchId,
  getSuggestJobsAll,
  searchIdFeature,
} from '../../utils/general'

const ActivationDialog = dynamic(() => import('components/ui/activationdialog'))

const Index2 = (props) => {
  const { lastblogposts, blogcatposts, suggestJobsAll } = props
  const [activationDialog, setActivationDialog] = useState({})
  const [searchId, setSearchId] = useState({})

  const { isLogin, setOnlogin } = useGlobalContext()
  const router = useRouter()

  useEffect(() => {
    //dataLayer.push({ page_type: props.pageType })
    pageLoadSearchIdEvent()
  }, [])

  useEffect(() => {
    queryProcessControl()
  }, [router.query])

  const pageLoadSearchIdEvent = () => {
    let loadSearchId
    //sayfa url ile bir kod oluşturuyoruz sayfaya özel id gibi
    //bu kod ile store da saklayıp geri yakalıyoruz
    const itemId = clearSearchIdUrl(router.asPath)
    loadSearchId = getSearchId({ itemId })

    if (!loadSearchId?.searchId || props.isServerRendered) {
      loadSearchId = {
        itemId: itemId,
        searchId: generateSearchId(),
        listName: props.pageType,
        itemIndex: 0,
      }
      addSearchId(loadSearchId)
      deleteOldSearchId({ oldSearchId: searchId })
    }

    setSearchId(loadSearchId)

    if (searchIdFeature) {
      const dataLayerObj = {
        search_id: loadSearchId?.searchId,
        page_type: props?.pageType,
      }
      dataLayer.push(dataLayerObj)
    }
  }

  const queryProcessControl = () => {
    if (isLogin) return
    if ('login' in router.query) onActivationDialogOpen('login')
    else if ('register' in router.query) {
      onActivationDialogOpen('register')
    }
  }

  const activationDialogClose = (loginResult) => {
    if (loginResult && typeof setOnlogin === 'function') {
      setOnlogin(true)
      window.location.href = activationDialog.returnUrl || '/is-ilanlari'
    }
    setActivationDialog({})
  }

  const onActivationDialogOpen = (type, message, returnUrl) => {
    setActivationDialog({
      disableClickOutside: true,
      processType: type,
      activationMessage: message ? message : '',
      returnUrl: returnUrl,
      open: true,
    })
  }

  return (
    <>
      <PageHead
        canonical={process.env.NEXT_PUBLIC_MAIN_URL2}
        title="Evinin Yakınındaki O İşi Hızlıca Bul"
        description="Binlerce ilan içerisinden evinin yakınındaki o işi hızlıca bul."
        pageType={props.pageType}
      />
      <HomeHeader2 onLoginDialogOpen={() => onActivationDialogOpen('login')} />
      <div className={styles.pageHome}>
        <HomeHeroBanner
          totaljobcount={props.totaljobcount}
          totalcandidatecount={props.totalcandidatecount}
        />
        {suggestJobsAll && (
          <HomeJobs
            items={suggestJobsAll}
            searchId={searchId}
            onOpenActivationDialog={(message, returnUrl) =>
              onActivationDialogOpen('login', message, returnUrl)
            }
            pageType={props.pageType}
          />
        )}

        {/* <Features /> */}
        <EmployerInfo />
        <ReferencesCompany />
        {lastblogposts?.length > 0 && (
          <BlogContent
            blogposts={lastblogposts}
            caption="Öne Çıkan İçerikler"
          />
        )}
        {blogcatposts?.length > 0 && (
          <BlogContent
            blogposts={blogcatposts}
            caption="Meslek Rehberi"
            link="mesleki-gelisim"
          />
        )}
        <SeoLinks items={props?.seoLinks} />
        {props.wikiBox && <WikiContent wikiContent={props.wikiBox} />}
        <Footer2 />
      </div>

      <AppRedirect />
      {activationDialog.open && (
        <ActivationDialog
          {...activationDialog}
          onClose={activationDialogClose}
        />
      )}
    </>
  )
}

export default Index2

const getSeoLinks = async (ctx) => {
  const params = {
    pageNumber: 1,
    pageSize: 20,
    sortingType: 2,
  }
  let result = []

  try {
    result = await services.wrapper(
      ctx,
      services.configs.seo.staticPositions.get,
      params
    )
    if (result?.data?.result) result = result.data.result
  } catch (err) {
    //
  }

  return result
}

const seoUrl = async (ctx) => {
  let respSeoUrl

  const url = 'anasayfa'

  const seoParams = { urlType: 1, url }

  try {
    respSeoUrl = await services.wrapper(
      ctx,
      services.configs.seourl.get,
      seoParams
    )
  } catch (error) {
    //
  }

  if (respSeoUrl?.data?.result) {
    const result = respSeoUrl.data.result
    if (result?.wikiboxUrl?.content) {
      const wikiBox = result.wikiboxUrl.content
      const wikiBoxParse = JSON.parse(wikiBox)
      const wikiBoxContent = JSON.parse(wikiBoxParse?.Content)
      if (wikiBoxContent) {
        return wikiBoxContent.content
      }
    }
  }

  return null
}

export async function getServerSideProps(ctx) {
  const data = {
    totaljobcount: 0,
    totalcandidatecount: 0,
    pageType: 'ana-sayfa',
    suggestJobsAll: [],
    wikiBox: null,
    isServerRendered: !process.browser,
  }

  const blogUrl = 'https://isinolsun.com/blog/'

  let queryParams = {
    _fields: 'id,title,content,date,link,yoast_head_json.og_image',
    per_page: 9,
    categories_exclude: '20,21,22,23,13',
  }

  const createQueryString = (params) => {
    return Object.entries(params)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')
  }

  const blogPromise1 = fetch(
    `${blogUrl}wp-json/wp/v2/posts?${createQueryString(queryParams)}`
  )

  delete queryParams?.categories_exclude
  const blogPromise2 = fetch(
    `${blogUrl}wp-json/wp/v2/posts?${createQueryString({
      ...queryParams,
      categories: '20,21,22,23,13',
    })}`
  )

  const seoUrlPromise = seoUrl()

  const totalJobCountPromise = services.wrapper(
    ctx,
    services.configs.jobs.count.get
  )

  const totalCandidateCountPromise = services.wrapper(
    ctx,
    services.configs.settings.applications.downloads.get
  )

  const suggestionPromise = getSuggestJobsAll(ctx)
  const seoLinksPromise = getSeoLinks(ctx)

  let respLastblogposts,
    respBlogcatposts,
    respWikiBox,
    respTotaljobcount,
    respTotalcandidatecount,
    respSuggestJobsAll,
    respSeoLinks

  try {
    ;[
      respWikiBox,
      respTotaljobcount,
      respTotalcandidatecount,
      respSuggestJobsAll,
      respSeoLinks,
      respLastblogposts,
      respBlogcatposts,
    ] = await Promise.all([
      seoUrlPromise,
      totalJobCountPromise,
      totalCandidateCountPromise,
      suggestionPromise,
      seoLinksPromise,
      blogPromise1,
      blogPromise2,
    ])
  } catch (error) {
    //
  }

  data.wikiBox = respWikiBox || null
  data.suggestJobsAll = respSuggestJobsAll || null
  data.seoLinks = respSeoLinks || null

  let lastBlogPosts, blogCatPosts
  try {
    ;[lastBlogPosts, blogCatPosts] = await Promise.all([
      respLastblogposts.json(),
      respBlogcatposts.json(),
    ])
  } catch (error) {
    //
  }

  data.lastblogposts = lastBlogPosts || null
  data.blogcatposts = blogCatPosts || null

  if (respTotaljobcount?.data?.result) {
    data.totaljobcount =
      formatNumberType1(respTotaljobcount.data.result?.count) || null
  }

  if (respTotalcandidatecount?.data?.result) {
    data.totalcandidatecount =
      formatNumberType1(respTotalcandidatecount.data.result?.count) || null
  }

  ctx.res.setHeader(
    'Cache-Control',
    'public, s-maxage=60, max-age=60, stale-while-revalidate=30'
  )

  return {
    props: data,
  }
}
